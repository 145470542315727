<template>
  <div class="autocomplete-input" @mouseleave="open = false">
    <div
      class="autocomplete-input__input"
      :class="{ 'autocomplete-input__input--loading': loading }"
    >
      <input
        required
        type="text"
        v-model="item"
        @keyup.prevent="
          $emit('searchMatches', item);
          open = true;
        "
        @focus="
          $emit('searchMatches', item);
          open = true;
        "
        :disabled="disabled"
      />
      <label class="autocomplete-input__label"
        >{{ placeholder
        }}<span class="autocomplete-input__label_required" v-if="required">*</span></label
      >
      <IconLoader
        class="autocomplete-input__icon"
        :class="{ 'autocomplete-input__icon--single': !item }"
        v-if="loading"
      ></IconLoader>
      <span
        v-if="item"
        class="autocomplete-input__icon autocomplete-input__icon--close"
        @click="
          $emit('selectMatch', { id: null, title: '' });
          item = '';
        "
      >
        <IconClose></IconClose>
      </span>
    </div>

    <div class="autocomplete-input__match" v-if="open">
      <p class="autocomplete-input__match_title">Search {{ placeholder }}</p>
      <template v-if="matches.length">
        <ul>
          <li v-for="match in matches" :key="match.id">
            <button
              @click.prevent.stop="
                match.title === value ? (item = value) : $emit('selectMatch', match)
              "
              class="autocomplete-input__match_item"
              :class="{ 'autocomplete-input__match_item--selected': match.title === value }"
            >
              {{ match.title }}
            </button>
          </li>
        </ul>
      </template>
      <h6 v-else>No Results Found</h6>
    </div>
    <p class="autocomplete-input__error">{{ validateError }}</p>
  </div>
</template>
<script>
import IconLoader from "@/components/icons/IconLoader.vue";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    loading: { type: Boolean, default: false },
    matches: {
      type: Array,
      default() {
        return [{ id: 1, title: "Title" }];
      },
    },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
  },
  components: { IconLoader, IconClose },
  data() {
    return {
      item: "",
      open: false,
    };
  },
  created() {
    this.item = this.value;
  },
  watch: {
    value: {
      handler: function (val) {
        this.item = val;
        this.$emit("input", this.item);
      },
      deep: true,
    },
    open(val) {
      if (!val) {
        this.item = this.value ? this.value : "";
        //this.$emit("searchMatches", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-input {
  position: relative;
  width: 100%;
  padding-bottom: 11px;
  margin-top: 10px;
  &__input {
    position: relative;
    margin-top: 5px;
    input {
      height: 9rem;
      padding: 0 4.4rem 0 2.7rem;
      border: 0.5px solid $lightGrey;
      border-radius: 12px;
      @include type();
      color: $lightBlack;
      transition: all 0.3s ease-in-out;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        height: 7rem;
        font-size: 14px;
      }
    }
    &--loading {
      input {
        padding-right: 4rem;
      }
    }
  }
  &--invalid {
    .autocomplete-input__input input {
      border-color: $error;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 5.5rem;
    transform: translateY(-50%);
    &--single {
      right: 2.4rem;
    }
    &--close {
      right: 2rem;
      cursor: pointer;
      ::v-deep g {
        fill: $black;
      }
    }
  }
  &__label {
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    color: $black;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
      color: $error;
      margin-left: 4px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  input:focus ~ &__label,
  input:valid ~ &__label {
    top: 0;
  }
  &__match {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24);
    padding: 24px 22px 16px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #efefef;
    z-index: 3;
    &_title {
      @include type($fw: 700);
      text-transform: capitalize;
      margin-bottom: 25px;
      color: $black;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &_item {
      @include type();
      color: $white;
      text-align: center;
      border-radius: 8px;
      background-color: $greyDark;
      padding: 6px 12px;
      margin-bottom: 8px;
      transition: all 0.3s ease-in-out;
      &:hover,
      &--selected {
        background-color: $blueLight;
        color: $blue;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    h6 {
      @include type($fw: 500);
      color: rgba($color: #111111, $alpha: 0.7);
      margin-bottom: 15px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
}
</style>